import React, {useState, useEffect, useRef} from 'react'
import PostList from '../components/PostList'
import '../styles/App.scss'
import PostForm from '../components/PostForm'
import MyModal from '../components/MyModal/MyModal'
import PostFilter from '../components/PostFilter'
import MyButton from '../components/UI/button/MyButton'
import { usePosts } from '../hooks/usePosts'
import PostService from '../API/PostService'
import Loader from '../components/UI/loader/Loader'
import useFetching from '../hooks/useFetching'
import { getPageCount } from '../components/utils/pages'
import Pagination from '../components/UI/pagination/Pagination'
import { useObserver } from '../hooks/useObserver'
import MySelect from '../components/UI/select/MySelect'

function Posts() {
	const [posts, setPosts] = useState([])
	// const bodyInputRef = useRef();
	const [filter, setFilter] = useState({sort: '', query: ''})
	const [modal, setModal] = useState(false)
	const [totalPages, setTotalPages] = useState(0)
	const [limit, setLimit] = useState(5)
	const [page, setPage] = useState(1)
	const sortedAndSearchedPosts = usePosts(posts, filter.sort, filter.query)
	const lastElement = useRef();

	const	[fetchPosts, isPostsLoading, postError] = useFetching(async (limit, page) => {
		const response = await PostService.getAll(limit, page)
		setPosts(response.data)
		// setPosts([...posts, ...response.data])
		const totalCount = response.headers['x-total-count']
		setTotalPages(getPageCount(totalCount, limit));
	})

	// useObserver(lastElement, page < totalPages, isPostsLoading, () => {
	// 	setPage(page + 1)
	// })

	useEffect(() => {
		fetchPosts(limit, page)
	}, [page, limit])

	const createPost = (newPost) => {
		setPosts([...posts, newPost])
		setModal(false)
	}

	const	removePost = (post) => {
		setPosts(posts.filter(p => p.id !== post.id))
	}

	const changePage = (page) => {
		setPage(page)
	}

  return (
    <div className="App">
			<MyButton style={{marginTop: '30px'}} onClick={() => setModal(true)}>Создать пост</MyButton>
			<MyModal visible={modal} setVisible={setModal}>
				<PostForm create={createPost}/>
			</MyModal>
			<hr style={{margin: '15px 0'}}/>
			<PostFilter filter={filter} setFilter={setFilter}/>
			<MySelect
				value={limit}
				onChange={value => {
					setLimit(value);
					setPage(1)
				}}
				defaultValue="Кол-во записей на странице"
				options={[
					{value: 5, name: '5'},
					{value: 10, name: '10'},
					{value: 25, name: '25'},
					{value: -1, name: 'Показать все записи'}
				]}
			/>
			{postError &&
				<h1>Произошла ошибка: ${postError}</h1>
			}

			{isPostsLoading
				? <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px 0'}}><Loader/></div>
				: <PostList remove={removePost} posts={sortedAndSearchedPosts} title="Посты про JS"/>
			}
			<div ref={lastElement} style={{height: 20, background: 'transparent'}}/>
			<Pagination page={page} changePage={changePage} totalPages={totalPages}/>
    </div>
  );
}

export default Posts;
