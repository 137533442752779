import React from 'react'

const About = () => {
	return (
		<h1>
			Это приложение создано TimaBor по видео Ulbi TV
		</h1>
	)
}

export default About