import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import PostService from '../API/PostService'
import {useFetching} from '../hooks/useFetching'
import Loader from '../components/UI/loader/Loader'

const PostIdPage = () => {
	const params = useParams()
	const [post, setPost] = useState({})
	const [comments, setComments] = useState([])
	const [fetchPostById, isLoading, error] = useFetching(async (id) => {
		const response = await PostService.getById(id)
		setPost(response.data)
	})
	const [fetchComments, isComLoading, comError] = useFetching(async (id) => {
		const response = await PostService.getCommentsPostById(id)
		setComments(response.data)
	})

	useEffect(() => {
		if( localStorage.getItem('pageId') ) {
			const id = localStorage.getItem('pageId')
			fetchPostById(id);
			fetchComments(id);
		}

		fetchPostById(params.id);
		fetchComments(params.id);
		localStorage.setItem('pageId', params.id);
	}, [])

	window.addEventListener('beforeunload', handleWindowBeforeUnload);

	function handleWindowBeforeUnload() {
		localStorage.removeItem('pageId')
	}

	return (
		<div>
			{ !error
				?
				<>
					<h1>Вы попали на страницу поста c Id = {params.id}</h1>
					{isLoading
						? <Loader/>
						: <div>{post.id}. {post.title}</div>
					}
					<h2>
					Комментарии
					</h2>
					{isComLoading
						? <Loader/>
						: <div>
							{comments.length > 0
								?
								<>
									{comments.map(comm =>
										<div key={comm.id} style={{marginTop: 15}}>
											<h5>{comm.email}</h5>
											<div>{comm.body}</div>
										</div>
									)}
								</>
								: <h3>Нет ни одного комментария</h3>
							}

						</div>
					}
				</>
				:  <h1>Такого поста не существует</h1>
			}
		</div>
	)
}

export default PostIdPage